<template>
    <div class="comment_input_box" v-if="checkWriteComment()">
        <div class="name">{{UserInfo.mb_name}}</div>
        <textarea
                class="write"
                style="resize: none;"
                maxlength="1000"
                v-model="commentTxt"
                @input="inputCommentText(commentTxt)"
                :disabled="!checkWriteComment()"
               placeholder="(권리침해, 욕설, 특정 대상을 비하하는 내용, 청소년에게 유해한 내용 등을 게시할 경우 운영정책과 이용약관 및 관련 법률에 의해 제재될 수 있습니다. 댓글 작성 시 배려와 책임을 담아 주세요.)">
        </textarea>
        <div class="write-info clear">
            <div class="length_box">
                <span class="length">{{commentTxt.length}}</span>/1000
            </div>
            <div class="button-wrap">
                ※ 이 댓글에 대한 법적 책임은 작성자에게 귀속됩니다.
                <button class="comment_btn" :disabled="commentTxt === ''" @click="runWrite">등록</button>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters, mapState} from "vuex";
    import EventBus from "@/utils/event-bus";
    export default {
        name: "CommentInputLetterLayout",
        mixins: [],
        components: {},
        //inject: ['commentSetData', 'commentWrite'],
        inject: {
            commentSetData: {
                default: () => {},
            },
            commentWrite: {
                default: () => {},
            },
            checkMyBoard: {
                default: () => {}
            },
        },
        props: {
        },
        data() {
            return {
                commentTxt: ''
            }
        },
        beforeRouterEnter() {
        },
        beforeCreate() {
        },
        created() {

        },
        beforeMount() {
        },
        mounted() {
            EventBus.$on('initCommentText', this.initCommentText)
        },
        beforeUpdate() {
        },
        updated() {
        },
        beforeRouterLeave() {
        },
        beforeDestroy() {
            EventBus.$off('initCommentText')
        },
        destroyed() {
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })
        },
        methods: {
            inputCommentText(value) {
                this.commentSetData('commentTxt', value);
            },
            initCommentText() {
                this.commentTxt = '';
                this.inputCommentText('');
            },
            runWrite() {
                this.commentWrite();
            },
            checkWriteComment() {
                if(this.UserInfo.mb_type === 0 ||this.UserInfo.mb_type === 3) {
                    return false;
                }
                return true;
            },
        },
        watch: {
        },
    }
</script>

<style scoped>

</style>